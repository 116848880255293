import { createSlice } from "@reduxjs/toolkit";
import { findBookmarkAPI } from "../../../service/hackerthon/hackerthon.bookmark.service";

const initialState = {
  isLoading: false,
  error: null,
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  bookmarks: [],
  search: {
    page: 0,
    size: 10,
    value: '',
    orders: [
      {
        order: "desc",
        property: 'createdAt'
      }
    ],
    filterBys: {
    }
  }
};

const slice = createSlice({
  name: 'bookmark',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setBookmarks(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.bookmarks = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setBookmarkSearch(state, action) {
      state.isLoading = false;
      state.search = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { setBookmarkSearch } = slice.actions;
// ----------------------------------------------------------------------

export function getBookmarks() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { hackerthonBookmark: { search } } = getState();

    const resp = await findBookmarkAPI({ ...search, value: `%${search.value}%` });
    if (resp.code === '200')
      dispatch(slice.actions.setBookmarks(resp));
    else
      dispatch(slice.actions.hasError(resp));
  };
}