import { createSlice } from "@reduxjs/toolkit";
import { findProjectAnswerAPI } from "../../../service/hackerthon/hackerthon.projectAnswer.service";

const initialState = {
  isLoading: false,
  error: null,
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  projectAnswers: [],
  projectAnswer: null,
  search: {
    page: 0,
    size: 10,
    value: '',
    orders: [
      {
        order: "desc",
        property: 'createdAt'
      }
    ],
    filterBys: {}
  },
};

const slice = createSlice({
  name: 'projectAnswer',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setProjectAnswers(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.projectAnswers = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setProjectAnswer(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.projectAnswer = response.data;
    },
    setProjectAnswerSearch(state, action) {
      state.isLoading = false;
      state.search = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { setProjectAnswerSearch } = slice.actions;
// ----------------------------------------------------------------------

export function getProjectAnswers() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    const { hackerthonProjectAnswer: { search } } = getState();

    const resp = await findProjectAnswerAPI({ ...search, value: `%${search.value}%` });
    if (resp.code === '200')
      dispatch(slice.actions.setProjectAnswers(resp));
    else
      dispatch(slice.actions.hasError(resp));
  };
}


