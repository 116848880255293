import { handleRequest } from '../../utils/axios';

export const statisticUser = async (data) => {
  const config = {
    url: '/user/ranking',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const updateMeInfoAPI = async (data) => {
  const config = {
    url: '/user/me/info',
    method: 'PUT',
    data
  };

  return handleRequest(config);
};

export const getMeAPI = async () => {
  const config = {
    url: `/user/me`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const getUserByIdAPI = async (id) => {
  const config = {
    url: `/user/profile/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};