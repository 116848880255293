import { createSlice } from "@reduxjs/toolkit";
import { findSolutionAPI } from "../../../service/hackerthon/hackerthon.solution.service";

const initialState = {
  isLoading: false,
  error: null,
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  solutions: [],
  solution: null,
  search: {
    page: 0,
    size: 10,
    value: '',
    orders: [
      {
        order: "desc",
        property: 'createdAt'
      }
    ],
    filterBys: {
      exerciseId: null
    }
  }
};

const slice = createSlice({
  name: 'solution',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setSolutions(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.solutions = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setSolution(state, action) {
      state.isLoading = false;
      state.solution = action.payload;
    },
    setSolutionSearch(state, action) {
      state.isLoading = false;
      state.search = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { setSolutionSearch, setSolution } = slice.actions;
// ----------------------------------------------------------------------

export function getSolutions() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    // eslint-disable-next-line no-unused-vars
    const { hackerthonSolution: { search: { tags, ...other } } } = getState();

    const resp = await findSolutionAPI({ ...other, value: `%${other.value}%` });
    if (resp.code === '200')
      dispatch(slice.actions.setSolutions(resp));
    else
      dispatch(slice.actions.hasError(resp));
  };
}
