import { handleRequest } from '../../utils/axios';

export const findExerciseAPI = async (data) => {
  const config = {
    url: '/exercise/search/active',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getExerciseBySlug = async (slug) => {
  const config = {
    url: `/exercise/slug/${slug}`,
    method: 'GET'
  };
  return handleRequest(config);
};