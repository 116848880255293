import { handleRequest } from '../../utils/axios';

export const findPracticeAPI = async (data) => {
  const config = {
    url: '/practice/search/active',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getPracticeBySlug = async (slug) => {
  const config = {
    url: `/practice/slug/${slug}`,
    method: 'GET'
  };
  return handleRequest(config);
};