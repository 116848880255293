import { createSlice } from "@reduxjs/toolkit";
import { findPracticeAPI } from "../../../service/hackerthon/hackerthon.practice.service";

const initialState = {
  isLoading: false,
  error: null,
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  practices: [],
  practice: null,
  openDrawer: false,
  search: {
    page: 0,
    size: 10,
    value: '',
    orders: [
      {
        order: "desc",
        property: 'createdAt'
      }
    ],
    filterBys: {
      tagIds: null
    }
  }
};

const slice = createSlice({
  name: 'practice',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setPractices(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.practices = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setPracticeSearch(state, action) {
      state.isLoading = false;
      state.search = action.payload;
    },
    setPractice(state, action) {
      state.isLoading = false;
      state.practice = action.payload;
    },
    setOpenDrawer(state, action) {
      state.openDrawer = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { setPracticeSearch, setPractice, setOpenDrawer } = slice.actions;
// ----------------------------------------------------------------------

export function getPractices() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    // eslint-disable-next-line no-unused-vars
    const { hackerthonPractice: { search: { tags, ...other } } } = getState();
    const resp = await findPracticeAPI({ ...other, value: `%${other.value}%` });
    if (resp.code === '200')
      dispatch(slice.actions.setPractices(resp));
    else
      dispatch(slice.actions.hasError(resp));
  };
}