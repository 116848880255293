import { handleRequest } from '../../utils/axios';

export const findPracticeAnswerAPI = async (data) => {
  const config = {
    url: '/practice/answer/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const createPracticeAnswerAPI = async (data) => {
  const config = {
    url: '/practice/answer/',
    method: 'POST',
    data
  };

  return handleRequest(config);
};
