import { handleRequest } from '../../utils/axios';

export const findProjectAPI = async (data) => {
  const config = {
    url: '/project/search/active',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getProjectBySlug = async (slug) => {
  const config = {
    url: `/project/slug/${slug}`,
    method: 'GET'
  };
  return handleRequest(config);
};