import { handleRequest } from '../../utils/axios';

export const findAnswerAPI = async (data) => {
  const config = {
    url: '/answer/search',
    method: 'POST',
    data,
  };
  return handleRequest(config);
};

export const getAnswerByIdAPI = async (id) => {
  const config = {
    url: `/answer/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const findPublicAnswerAPI = async (data) => {
  const config = {
    url: '/answer/public/search',
    method: 'POST',
    data,
  };
  return handleRequest(config);
};
