import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
// slices
// import blogTagReducer from './slices/blog/blog.tag';
import blogBookmarkReducer from './slices/blog/blog.bookmark';
import blogCommentReducer from './slices/blog/blog.comment';
import blogForumPostReducer from './slices/blog/blog.forumPost';
import blogPostReducer from './slices/blog/blog.post';
import blogQuestionReducer from './slices/blog/blog.question';
import blogTagReducer from './slices/blog/blog.tag';

import hackerthonAnswerReducer from './slices/hackerthon/hackerthon.answer';
import hackerthonBookmarkReducer from './slices/hackerthon/hackerthon.bookmark';
import hackerthonExerciseReducer from './slices/hackerthon/hackerthon.exercise';
import hackerthonPracticeReducer from './slices/hackerthon/hackerthon.practice';
import hackerthonPracticeAnswerReducer from './slices/hackerthon/hackerthon.practiceAnswer';
import hackerthonProjectReducer from './slices/hackerthon/hackerthon.project';
import hackerthonProjectAnswerReducer from './slices/hackerthon/hackerthon.projectAnswer';
import hackerthonSolutionReducer from './slices/hackerthon/hackerthon.solution';
import hackerthonTagReducer from './slices/hackerthon/hackerthon.tag';
import hackerthonUserReducer from './slices/hackerthon/hackerthon.user';
// ----------------------------------------------------------------------

const createNoopStorage = () => ({
  getItem() {
    return Promise.resolve(null);
  },
  setItem(_key, value) {
    return Promise.resolve(value);
  },
  removeItem() {
    return Promise.resolve();
  },
});

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  hackerthonExercise: persistReducer({ ...rootPersistConfig, key: "hackerthon-exercise" }, hackerthonExerciseReducer),
  hackerthonAnswer: persistReducer({ ...rootPersistConfig, key: "hackerthon-answer" }, hackerthonAnswerReducer),
  hackerthonTag: persistReducer({ ...rootPersistConfig, key: "hackerthon-tag" }, hackerthonTagReducer),
  hackerthonSolution: persistReducer({ ...rootPersistConfig, key: "hackerthon-solution" }, hackerthonSolutionReducer),
  user: persistReducer({ ...rootPersistConfig, key: "hackerthon-user" }, hackerthonUserReducer),
  hackerthonBookmark: persistReducer({ ...rootPersistConfig, key: "hackerthon-bookmark" }, hackerthonBookmarkReducer),
  hackerthonPractice: persistReducer({ ...rootPersistConfig, key: "hackerthon-practice" }, hackerthonPracticeReducer),
  hackerthonPracticeAnswer: persistReducer({ ...rootPersistConfig, key: "hackerthon-practice-answer" }, hackerthonPracticeAnswerReducer),
  hackerthonProject: persistReducer({ ...rootPersistConfig, key: "hackerthon-project" }, hackerthonProjectReducer),
  hackerthonProjectAnswer: persistReducer({ ...rootPersistConfig, key: "hackerthon-project-answer" }, hackerthonProjectAnswerReducer),
  // blog
  blogBookmark: persistReducer({ ...rootPersistConfig, key: "blog-bookmark" }, blogBookmarkReducer),
  blogPost: persistReducer({ ...rootPersistConfig, key: "blog-post" }, blogPostReducer),
  blogComment: persistReducer({ ...rootPersistConfig, key: "blog-comment" }, blogCommentReducer),
  blogTag: persistReducer({ ...rootPersistConfig, key: "blog-comment" }, blogTagReducer),
  blogForumPost: persistReducer({ ...rootPersistConfig, key: "blog-forum-post" }, blogForumPostReducer),
  blogQuestion: persistReducer({ ...rootPersistConfig, key: "blog-question" }, blogQuestionReducer)
});

export { rootPersistConfig, rootReducer };

