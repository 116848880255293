import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import NProgress from 'nprogress';
import { BASEURL, BLOGURL } from '../config';
// ----------------------------------------------------------------------
const axiosInstance = axios.create({ baseURL: BASEURL, timeout: 30000 });
const axiosBlog = axios.create({ baseURL: BLOGURL, timeout: 30000 });

const handleResponse = async response => {
  if (typeof window !== 'undefined')
    NProgress.done();
  return (response);
};

const addBearerToken = async (config) => {
  config.headers = {
    ...config.headers,
    'Tenant': "hackerthon"
  };

  if (typeof window !== 'undefined') {
    const AUTH = firebase.auth();
    const accessToken = await AUTH.currentUser?.getIdToken();
    if (accessToken)
      config.headers = {
        ...config.headers,
        'Authorization': `Bearer ${accessToken}`
      };
    NProgress.start();
    return config;
  }

  return config;
};

// Request interceptor for API calls
axiosInstance.interceptors.request.use(addBearerToken);
axiosInstance.interceptors.response.use(handleResponse,
  async (error) => {
    if (typeof window !== 'undefined') {
      NProgress.done();
      const AUTH = firebase.auth();

      const originalRequest = error.config;
      if (error.response && (error.response.status === 401) && !originalRequest._retry) {
        originalRequest._retry = true;
        await AUTH.currentUser.getIdToken(true);
        return axiosInstance(originalRequest);
      }
      if (error.response && (error.response.status === 401)) {
        await AUTH.signOut();
      }
    }
    throw (error);
  });

axiosBlog.interceptors.request.use(addBearerToken);
axiosBlog.interceptors.response.use(handleResponse,
  async (error) => {
    if (typeof window !== 'undefined') {
      NProgress.done();
      const AUTH = firebase.auth();

      const originalRequest = error.config;
      if (error.response && (error.response.status === 401) && !originalRequest._retry) {
        originalRequest._retry = true;
        await AUTH.currentUser.getIdToken(true);
        return axiosBlog(originalRequest);
      }
      if (error.response && (error.response.status === 401)) {
        await AUTH.signOut();
      }
    }
    throw (error);
  });

export const handleRequestBlog = async (config) => {
  try {
    const resp = await axiosBlog(config);
    return resp.data;
  } catch (error) {
    console.log(error);
    if (error.response)
      return (error.response.data);

    return ({ code: "408", message: error.message });
  }
};

export const handleRequest = async (config) => {
  try {
    const resp = await axiosInstance(config);
    return resp.data;
  } catch (error) {
    console.log(error);
    if (error.response)
      return (error.response.data);

    return ({ code: "408", message: error.message });
  }
};