import { createSlice } from "@reduxjs/toolkit";
import { findTagAPI } from "../../../service/hackerthon/hackerthon.tag.service";

const initialState = {
  isLoading: false,
  error: null,
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  tags: [],
  categories: [],
  levels: [],
  companies: [],
  plans: [],
  topics: [],
  schools: [],
  tag: null,
  search: {
    page: 0,
    size: 12,
    value: '',
    orders: [
      {
        order: "asc",
        property: 'orderNo'
      }
    ],
    filterBys: {
    }
  }
};

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setTags(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.tags = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setTopics(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.topics = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setCategories(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.categories = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setLevels(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.levels = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setSchools(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.schools = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setCompanies(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.companies = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setPlans(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.plans = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setTag(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.tag = response.data;
    },
    setTagSearch(state, action) {
      state.isLoading = false;
      state.search = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { setTagSearch } = slice.actions;
// ----------------------------------------------------------------------

export function getTags() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    const { hackerthonTag: { search } } = getState();

    const resp = await findTagAPI({ ...search, value: `%${search.value}%` });
    if (resp.code === '200')
      dispatch(slice.actions.setTags(resp));
    else
      dispatch(slice.actions.hasError(resp));
  };
}

export function getTopics() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    const { hackerthonTag: { search } } = getState();

    const resp = await findTagAPI({ ...search, value: `%${search.value}%`, filterBys: { ...search.filterBys, type: 'TOPIC' } });
    if (resp.code === '200')
      dispatch(slice.actions.setTopics(resp));
    else
      dispatch(slice.actions.hasError(resp));
  };
}

export function getCategories() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    const { hackerthonTag: { search } } = getState();

    const resp = await findTagAPI({ ...search, value: `%${search.value}%`, filterBys: { ...search.filterBys, type: 'CATEGORY' } });
    if (resp.code === '200')
      dispatch(slice.actions.setCategories(resp));
    else
      dispatch(slice.actions.hasError(resp));
  };
}

export function getSchools() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    const { hackerthonTag: { search } } = getState();

    const resp = await findTagAPI({ ...search, value: `%${search.value}%`, filterBys: { ...search.filterBys, type: 'SCHOOL' } });

    if (resp.code === '200')
      dispatch(slice.actions.setSchools(resp));
    else
      dispatch(slice.actions.hasError(resp));
  };
}

export function getLevels() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    const { hackerthonTag: { search } } = getState();

    const resp = await findTagAPI({ ...search, value: `%${search.value}%`, filterBys: { ...search.filterBys, type: 'LEVEL' } });
    if (resp.code === '200')
      dispatch(slice.actions.setLevels(resp));
    else
      dispatch(slice.actions.hasError(resp));
  };
}

export function getPlans() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    const { hackerthonTag: { search } } = getState();

    const resp = await findTagAPI({ ...search, value: `%${search.value}%`, filterBys: { ...search.filterBys, type: 'PLAN' } });
    if (resp.code === '200')
      dispatch(slice.actions.setPlans(resp));
    else
      dispatch(slice.actions.hasError(resp));
  };
}

export function getCompanies() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    const { hackerthonTag: { search } } = getState();

    const resp = await findTagAPI({ ...search, value: `%${search.value}%`, filterBys: { ...search.filterBys, type: 'COMPANY' } });
    if (resp.code === '200')
      dispatch(slice.actions.setCompanies(resp));
    else
      dispatch(slice.actions.hasError(resp));
  };
}

export function getTAGTypes() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    const { hackerthonTag: { search } } = getState();

    const resp = await findTagAPI({ ...search, value: `%${search.value}%`, filterBys: { ...search.filterBys, type: 'TAG' } });
    if (resp.code === '200')
      dispatch(slice.actions.setTopics(resp));
    else
      dispatch(slice.actions.hasError(resp));
  };
}