import { handleRequest } from '../../utils/axios';

export const findProjectAnswerAPI = async (data) => {
  const config = {
    url: '/project-answer/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getProjectAnswerBySlugAPI = async (slug) => {
  const config = {
    url: `/project-answer/slug/${slug}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const createProjectAnswerAPI = async (data) => {
  const config = {
    url: '/project-answer/',
    method: 'POST',
    data
  };

  return handleRequest(config);
};

export const updateProjectAnswerAPI = async (data) => {
  const config = {
    url: '/project-answer/',
    method: 'PUT',
    data
  };

  return handleRequest(config);
};

export const deleteProjectAnswerAPI = async (id) => {
  const config = {
    url: `/project-answer/${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const deleteProjectAnswersAPI = async (ids) => {
  const config = {
    url: `/project-answer/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};
